
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { currentLanguageISOString, getTranslated } from '@/translation';
import { fetchVideo } from '@/utils/youtube-api';
import { filterObject, isEqual, KeyOf, OptionalKeys } from '@/utils/object';
import { Product, useVersionStore } from '@/stores/version';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import { Webinar, useVideoStore, defaultWebinar, slug } from '@/stores/video';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-video-webinar-editor',
  props: {
    modelValue: Object as PropType<Webinar>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// WEBINAR
    ///-------------------------------------------------------------------

    const initialWebinar: OptionalKeys<Webinar, '_id' | '_rev'> = {
      ...defaultWebinar,
      date: new Date().toISOString(),
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const webinar = ref<OptionalKeys<Webinar, '_id' | '_rev'>>({ ...clone(initialWebinar), ...props.modelValue ? clone(props.modelValue) : {} });
    watch(() => props.modelValue, reset, { immediate: true });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(webinar.value, props.modelValue)) { return }
      webinar.value = { ...clone(initialWebinar), ...props.modelValue ? clone(props.modelValue) : {} };
    }

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    function parentSortProperty(item: Webinar): KeyOf<Webinar> {
      return item._id === 'root' ? 'order' : `name.${currentLanguageISOString()}`;
    }

    ///-------------------------------------------------------------------
    /// PRODUCT
    ///-------------------------------------------------------------------

    function transformProduct(product: Product) {
      return versionStore.getName(product);
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Webinar>()

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    const store = useVideoStore(true);

    async function save() {
      let response;
      webinar.value.doc_type = 'video_webinar';
      if (!webinar.value.slug) {
        webinar.value.slug = slug(webinar.value, store.getVideos());
      }
      if (webinar.value._id) {
        response = await store.update(webinar.value);
      } else {
        response = await store.create(webinar.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: webinar.value._id
            ? $gettext('Webinar successfully updated')
            : $gettext('Webinar successfully created'),
          content: webinar.value._id
            ? $gettext('The webinar was successfully updated.')
            : $gettext('The webinar was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.video.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: webinar.value._id
            ? $gettext('Failed to update webinar')
            : $gettext('Failed to create webinar'),
          content: webinar.value._id
            ? $gettext('The webinar could not be updated: %{error}', { error: response?.error })
            : $gettext('The webinar could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// YOUTUBE
    ///-------------------------------------------------------------------

    function pasteYoutubeId(event: ClipboardEvent) {
      const text = event.clipboardData?.getData('text') || '';
      if (/^https?:\/\/.*youtube.*/.test(text)) {
        webinar.value.youtube_id = (new Proxy(new URLSearchParams(text.split('?')[1]), {
          get: (searchParams, prop: string) => searchParams.get(prop),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any).v
        return;
      } else if (/^https?:\/\/.*youtu\.be.*/.test(text)) {
        webinar.value.youtube_id = text.split('?')[0].split('/').pop() ?? '';
        return;
      }
      webinar.value.youtube_id = text;
    }

    async function fetchData() {
      const id = webinar.value.youtube_id;
      if (!id) {
        return;
      }

      // Fetch video data from YouTube Data API
      const { name, description, tags } = await fetchVideo(id);
      webinar.value.name = { ...name, ...filterObject(webinar.value.name || {}, (_key, value) => !!value)}
      webinar.value.description = { ...description, ...filterObject(webinar.value.description || {}, (_key, value) => !!value && value !== '<p></p>')}
      webinar.value.tags = Array.from(new Set([...tags, ...(webinar.value.tags || [])].map(tag => tag.toLowerCase())));
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      currentLanguageISOString,
      errors,
      fetchData,
      getTranslated,
      isEdit,
      parentSortProperty,
      pasteYoutubeId,
      reset,
      save,
      saveDisabled,
      setError,
      slashIcon,
      slug,
      store,
      transformProduct,
      versionStore,
      webinar,
    }
  }
})


