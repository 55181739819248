import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_2 = { style: {"opacity":"0.5"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    class: "bp-video-webinar-editor",
    loading: _ctx.loading,
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createTextVNode("New webinar")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { webinar: _ctx.getTranslated(_ctx.webinar.name) }
          }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [
              _createTextVNode("Edit webinar \"%{webinar}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.video.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.webinar)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-video-webinar-editor__form",
            onSubmit: _cache[27] || (_cache[27] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_ctx.webinar.webinar)
              ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                  key: 0,
                  "intense-border": "",
                  legend: _ctx.$gettext('Webinar settings')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bp_input, {
                      indent: 1.125,
                      label: _ctx.$gettext('Start date & time'),
                      onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('webinar.date', _ctx.$gettext('Start date & time'), $event))),
                      required: "",
                      type: "datetime",
                      modelValue: _ctx.webinar.webinar.date,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.webinar.webinar.date) = $event))
                    }, null, 8, ["label", "modelValue"]),
                    _createVNode(_component_bp_input, {
                      indent: 1.125,
                      label: _ctx.$gettext('Active'),
                      text: _ctx.$gettext('This webinar is currently active.'),
                      onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('webinar.active', _ctx.$gettext('Active'), $event))),
                      flush: "",
                      type: "checkbox",
                      modelValue: _ctx.webinar.webinar.active,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.webinar.webinar.active) = $event))
                    }, null, 8, ["label", "text", "modelValue"]),
                    _createVNode(_component_bp_select, {
                      multiple: "",
                      indent: 1.125,
                      label: _ctx.$gettext('Participants'),
                      text: _ctx.$gettext('The selected language for the participants will be the language for the email reminders.'),
                      modelValue: _ctx.webinar.webinar.participants,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.webinar.webinar.participants) = $event)),
                      onChangeValid: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setError('webinar.participants', _ctx.$gettext('Participants'), $event))),
                      "create-new-on-enter": "",
                      clearable: ""
                    }, null, 8, ["label", "text", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["legend"]))
              : _createCommentVNode("", true),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('YouTube ID'),
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bp_input, {
                  onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('youtube_id', _ctx.$gettext('YouTube ID'), $event))),
                  onPaste: _withModifiers(_ctx.pasteYoutubeId, ["stop","prevent"]),
                  required: "",
                  modelValue: _ctx.webinar.youtube_id,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.webinar.youtube_id) = $event))
                }, {
                  text: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Copy the ID from the URL of any YouTube video, e.g.")
                        ])),
                        _: 1
                      }),
                      _cache[31] || (_cache[31] = _createTextVNode(" ")),
                      _cache[32] || (_cache[32] = _createElementVNode("a", {
                        href: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
                        target: "_blank",
                        style: {"color":"var(--theme-text-secondary)","text-decoration":"none"}
                      }, [
                        _createTextVNode("https://www.youtube.com/watch?v="),
                        _createElementVNode("strong", null, "dQw4w9WgXcQ")
                      ], -1))
                    ])
                  ]),
                  _: 1
                }, 8, ["onPaste", "modelValue"]),
                _createVNode(_component_bp_button, {
                  class: "flex-0 ws-nw",
                  disabled: !_ctx.webinar.youtube_id,
                  icon: ['far', 'globe'],
                  large: "",
                  onClick: _ctx.fetchData
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("Fetch data")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.webinar.name,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.webinar.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.store.getParents({ excludeIds: _ctx.webinar._id, includeRoot: true }).map(webinar => ({ ...webinar, icon: ['far', 'folder']})),
              label: _ctx.$gettext('Parent category'),
              "search-property": `name.${_ctx.currentLanguageISOString()}`,
              "sort-property": _ctx.parentSortProperty,
              onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent category'), $event))),
              required: "",
              modelValue: _ctx.webinar.parent_id,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.webinar.parent_id) = $event))
            }, {
              option: _withCtx(({ option }) => [
                _createVNode(_component_bp_icon, {
                  icon: option.icon
                }, null, 8, ["icon"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(option.name)), 1),
                (_ctx.store.getPathById(option._id).length > 0)
                  ? (_openBlock(), _createElementBlock("em", _hoisted_1, [
                      _cache[34] || (_cache[34] = _createTextVNode(" (")),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getPathById(option._id), (category, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: category._id
                        }, [
                          (index !== 0)
                            ? (_openBlock(), _createBlock(_component_bp_icon, {
                                key: 0,
                                class: "ml-sm mr-sm",
                                icon: "angle-right"
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(_ctx.getTranslated(category.name)), 1)
                        ], 64))
                      }), 128)),
                      _cache[35] || (_cache[35] = _createTextVNode(") "))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              "option-root": _withCtx(({ option }) => [
                _createVNode(_component_bp_icon, {
                  icon: _ctx.slashIcon(option.icon)
                }, null, 8, ["icon"]),
                _createElementVNode("em", _hoisted_2, "(" + _toDisplayString(_ctx.getTranslated(option.name)) + ")", 1)
              ]),
              _: 1
            }, 8, ["data", "label", "search-property", "sort-property", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Description'),
              onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('description', _ctx.$gettext('Description'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.webinar.description,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.webinar.description) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_wysiwyg_editor, {
              label: _ctx.$gettext('Hint'),
              onChangeValid: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setError('hint', _ctx.$gettext('Hint'), $event))),
              type: "textarea",
              maxlength: 500,
              modelValue: _ctx.webinar.hint,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.webinar.hint) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('URL slug'),
              placeholder: _ctx.slug(_ctx.webinar, _ctx.store.getVideos()),
              onChangeValid: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setError('slug', _ctx.$gettext('URL slug'), $event))),
              modelValue: _ctx.webinar.slug,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.webinar.slug) = $event)),
              maxlength: 200
            }, {
              text: _withCtx(() => [
                _createElementVNode("span", null, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[36] || (_cache[36] = [
                      _createTextVNode("The part of the URL that identifies this particular recording in an easy-to-read form.")
                    ])),
                    _: 1
                  }),
                  _cache[38] || (_cache[38] = _createTextVNode(" ")),
                  _createVNode(_component_translate, { tag: "strong" }, {
                    default: _withCtx(() => _cache[37] || (_cache[37] = [
                      _createTextVNode("It should be in English for consistency reasons. Defaults to the english name of this recording.")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["label", "placeholder", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Date'),
              onChangeValid: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Date'), $event))),
              type: "date",
              modelValue: _ctx.webinar.date,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.webinar.date) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.versionStore.getRootProducts(),
              transform: _ctx.transformProduct,
              label: _ctx.$gettext('Product'),
              clearable: "",
              modelValue: _ctx.webinar.product,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.webinar.product) = $event))
            }, null, 8, ["data", "transform", "label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Order'),
              modelValue: _ctx.webinar.order,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.webinar.order) = $event)),
              type: "number",
              onChangeValid: _cache[22] || (_cache[22] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event)))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_bp_select, {
              data: _ctx.store.getTags().map(tag => ({ ...tag, icon: 'hashtag' })),
              label: _ctx.$gettext('Tags'),
              onChangeValid: _cache[23] || (_cache[23] = ($event: any) => (_ctx.setError('tags', _ctx.$gettext('Tags'), $event))),
              clearable: "",
              "create-new-on-enter": "",
              multiple: "",
              "search-property": "name",
              modelValue: _ctx.webinar.tags,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.webinar.tags) = $event))
            }, null, 8, ["data", "label", "modelValue"]),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Featured'),
              text: _ctx.$gettext('Feature this webinar at the top of the video area.'),
              onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Featured'), $event))),
              flush: "",
              type: "checkbox",
              modelValue: _ctx.webinar.featured,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.webinar.featured) = $event))
            }, null, 8, ["label", "text", "modelValue"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}